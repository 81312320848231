<template>
    <section class=" banner-container w-100 d-flex justify-content-around">
        <div class="opiniones-title">
            <h1 class="blue-text title" style="text-align: start;">Opiniones Ibancar</h1>
            <p class="blue-text subtitle" style="margin: 0; text-align: start;">Nuestros clientes nos avalan</p>
            <div class="review-widget_net d-none d-sm-block mt-3"
                 data-uuid="9c8b113f-e480-46ac-87cd-cf0e14cf65fa"
                 data-template="2"
                 data-lang="es"
                 data-theme="light"></div>
        </div>
        <div class="opiniones-image">
            <img src="@/assets/img/Group382.png"
                 alt="hombre con celular" />
        </div>
        <div class="opiniones-image-small ">
            <img src="@/assets/img/Grop1234.png"
                 alt="hombre con celular" />
        </div>
        <div class="fade1"></div>
    </section>
    <div class="rectangle "></div>
    <div class="container-fluid p-5 main-container">

        <div class="container-fluid">
            <div>
                <h2 class="blue-text title text-center">Qué opinan nuestros clientes</h2>
                <div style="width: 100%; margin-top: 3rem">
                    <div class="review-widget_net"
                         data-uuid="9c8b113f-e480-46ac-87cd-cf0e14cf65fa"
                         data-template="13"
                         data-lang="es"
                         data-theme="light"
                         data-filter="5"></div>
                </div>

            </div>
        </div>
    </div>

    <div class="container-fluid background-color p-5">
        <div>
            <h2 class="blue-text title text-center"
                style=" margin-bottom: 3rem;">Según las opiniones de nuestros clientes destacamos por
            </h2>

            <div class="cards-container">
                <div v-for="destacado in destacados"
                     class="card"
                     :key="destacado.title">
                    <span class="blue-text"
                          style="font-family: 'Montserrat-bold';">
                        {{ destacado.title }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid p-5">
        <figure class="video-container">
            <iframe width="560"
                    height="315"
                    src="https://www.youtube.com/embed/-zGn1k8UJnc?si=IyivKGTdtS4-Y-Ai"
                    title="Resolviendo dudas ibancar"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen></iframe>
        </figure>
    </div>

    <div class="container-fluid background-color p-5">
        <div>
            <h2 class="blue-text title text-center"
                style=" margin-bottom: 3rem;">¿Cómo es el préstamo con el coche como aval?</h2>

            <div class="faq-container">
                <p>El préstamo de Ibancar funciona como cualquier préstamo personal de una entidad financiera. La
                    diferencia
                    es que puedes conseguir el dinero de forma online y el mismo día. Además, el coche sólo es la
                    garantía
                    del préstamo.</p>
                <p> En Ibancar no cambiamos la titularidad del coche, por lo que el coche siempre estará a su nombre,
                    pero
                    por contrato tendrá prohibida la venta durante el mismo, ya que el coche es el aval del préstamo.
                </p>
                <p>
                    En resumen es un préstamo personal con aval. Tras su solicitud un agente se pondrá en contacto para
                    explicarte las condiciones del préstamo, cantidad y cuotas, tú eliges la fecha de pago que mejor te
                    convenga y siempre tendrás 5 días de cortesía.
                </p>
                <p>
                    Una vez enviada la documentación, nuestro departamento de Riesgos aprobará tu préstamo en un máximo
                    de 2
                    horas. Te enviaremos el contrato para que lo firmes de forma digital y en el mismo día consigues el
                    dinero en tu cuenta bancaria. ¡Así de fácil y sencillo!
                </p>
                <p>
                    Después de la firma, todos los meses tendrás que abonar la cuota, mediante transferencia, tarjeta de
                    crédito o domiciliación y tendrás a tu disposición a un agente para ayudarte en cualquier momento
                </p>
                <p>
                    En el caso que quieras cancelar el préstamo, como cualquier préstamo, es posible cuando quieras y la
                    penalización es sólo 1% del capital que te quede pendiente por amortizar.
                </p>
            </div>
        </div>
    </div>

    <div class="container-fluid p-5 main-container">

        <div class="container-fluid">
            <div>
                <h2 class="blue-text title text-center">¿Necesitas recibir tu préstamo hoy?</h2>
            </div>
            <div class="selector-container">
                <div class="card-box">
                    <div class="row justify-content-center aling-content-center mb-md-3 flex-column">
                        <div class="">
                            <div class="row justify-content-center">
                                <p class="title-amount text-center" style="font-size: 15px;" >Necesito un prestamo de:</p>
                                <p class="box-title-h1 md_form_res">{{ formatNumber(formulario.dinero) + "€" }}</p>
                            </div>
                            <div>
                                <div id="container_input_range"
                                     class="row justify-content-center ">
                                    <div class="col-md-12 col-lg-11 col-xl-12">
                                        <div class="text-center ml-3 btn_menos_lg">
                                            <span class="btn_menos"
                                                  @click="sumarDinero(-100)"> - </span>
                                            <span class="euros">300€</span>
                                        </div>
                                        <input type="range"
                                               min="300"
                                               max="6000"
                                               step="100"
                                               v-model="formulario.dinero" />
                                        <div class="text-center btn_mas_lg">
                                            <span class="btn_mas"
                                                  @click="sumarDinero(100)"> + </span>
                                            <span class="euros">6.000€</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="row text-center justify-content-center pt-5">
                                <router-link to="/formulario/step1"
                                             class="btn btn-orange text-center align-middle ">Solicitar
                                    ahora</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid p-5 main-container">

        <div class="container-fluid">
            <div>
                <h2 class="blue-text title text-center">Colaboramos con las mejores marcas del sector</h2>

            </div>
        </div>
    </div>

    <div id="desktop">
        <div id="socios"
             class="d-flex flex-row justify-content-center text-wrap m-4">
            <a href="https://www.iuvo-group.com/es/originator-ibancar/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-iuvo.webp"
                     alt="iuvo"></a>
            <a href="http://www.asnef.com/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-asnef.webp"
                     alt="asnef"></a>
            <a href="https://www.ganvam.es/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-ganvam.webp"
                     alt="ganvam"></a>
            <a href="https://www.bdo.es/es-es/home"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/BDO_logo-198_47.webp"
                     alt="bdo"></a>
            <a href="https://www.netsuite.com/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/oracle-netsuite-logo-198_47.webp"
                     alt="oracle"></a>
            <a href="https://kineox.com/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-kineox.webp"
                     alt="oracle"></a>
        </div>
    </div>

    <div id="carousel">
        <div id="mobile"
             class="carousel slide text-center"
             data-touch="true"
             data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-iuvo.webp"
                             class="card-img-top"
                             alt="ibancar_iuvo">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-midas.webp"
                             class="card-img-top"
                             alt="ibancar_midas">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-asnef.webp"
                             class="card-img-top"
                             alt="ibancar_asnef">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-mrw.webp"
                             class="card-img-top"
                             alt="ibancar_mrw">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-ganvam.webp"
                             class="card-img-top"
                             alt="ibancar_ganvam">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/BDO_logo-198_47.webp"
                             class="card-img-top"
                             alt="ibancar_bdo">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/oracle-netsuite-logo-198_47.webp"
                             class="card-img-top"
                             alt="ibancar_oracle">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img
                             height="50"
                             src="@/assets/img/logo-kineox.webp"
                             class="card-img-top"
                             alt="kineox">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-psd2.webp"
                             class="card-img-top"
                             alt="psd2">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// @ is an alias to /src
import { useMeta } from 'vue-meta'
import { mapState } from "vuex";

export default {
    name: 'OpinionesClientes',
    components: {
    },
    data() {
        return {
            destacados: [
                { title: 'Mantienes la titularidad del coche' },
                { title: 'Es un préstamo y no un empeño' },
                { title: 'Fácil y rápido' },
                { title: 'Proceso 100% online' },
                { title: 'Trato inmejorable' },
                { title: 'Dinero en el mismo día' }

            ], settings: {
                itemsToShow: 1,
                snapAlign: "center",
                initialSlide: 0
            },
            dinero: 0
        }
    },
    methods: {
        sumarDinero: function (suma) {
            let calculo = parseFloat(this.formulario.dinero) + suma;
            if (calculo >= 300 && calculo <= 6000) {
                this.formulario.dinero = calculo;
            }
        },
        formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
    computed: mapState({
        step: (state) => state.formulario.step,
        data_matricula: (state) => state.formulario.data_matricula,
        title: (state) => state.section1.title,
        formulario: (state) => state.formulario,
    }),
    setup() {
        useMeta({
            title: 'Opiniones Clientes',
            meta: [
                { name: 'robots', content: 'noindex' },
                { name: 'description', content: 'Ibancar es una empresa que otorga préstamos personales con aval de coche. Hemos logrado transformar el sector, convirtiendo el proceso 100% online y sin cambiar la titularidad del coche.' },
                { name: 'keywords', content: 'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.' }
            ],
            link: [
                //   { href: 'https://ibancar.com/quienes-somos', rel: 'alternate', hreflang: 'es-es' },
                //   { href: 'https://ibancar.mx/quienes-somos', rel: 'alternate', hreflang: 'es-MX' }
            ]
        })
    }
}
</script>

<style scoped lang="scss">
// Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.card img {
    width: 120px !important;
}

.title-amount {
    font-family: 'Montserrat-bold';
    margin: 0;
}

.opiniones-image-small {
    display: none;
}

.selector-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.title {
    font-size: 32px;
    font-family: 'Montserrat-bold' !important;
    font-style: normal;
    font-weight: 600;
}

h1.title {
    font-size: 48px !important;
}

.subtitle {
    font-size: 32px;
}

.blue-text {
    color: #004680;
    font-family: 'Montserrat';
    font-style: normal;
    line-height: normal;
}

.card {
    border-radius: 10px;
    background: #FFF;
    padding: 3rem;
    width: 356px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
}

.cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.opiniones-title {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.banner-container {
    position: relative;
    margin-top: 6rem;
}

.background-color {
    // transform: rotate(-8.411deg);
    background: rgba(255, 165, 0, 0.04);
}

.rectangle {
    width: 100%;
    height: 31px;
    background-color: #B3DCFF;
}

.fade1 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 165, 0, 0.39) 0.5%, rgba(255, 200, 100, 0.47) 26%, rgba(255, 255, 255, 0.00) 68.5%);
    width: 100%;
    height: 30px;
    z-index: 2;
}

.video-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.faq-container {
    padding: 0 7.5rem;

    p {
        color: #004680;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 16px;
    }
}

#socios {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}


#desktop {
    margin-bottom: 5rem;

    .card {
        z-index: -1;
        width: 19rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}



#socios {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}

@include media-breakpoint-down(sm) {

    .banner-container {

        // margin-bottom: 2rem;
        .opiniones-title {
            h1 {
                font-size: 1.6rem !important;
            }

            p {
                font-size: 1.1rem;
            }
        }
    }

    .banner-container {
        margin-top: 7rem;
        padding: 0 1rem;
    }

    .opiniones-image {
        width: 40%;
    }

    .opiniones-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            text-align: center;
        }
    }

    .opiniones-image img {
        width: 100%;
    }

    h1.title {
        font-size: 35px;
    }

    p.subtitle {
        font-size: 25px;
    }

    h2.blue-text {
        font-size: 20px !important;
    }

    .faq-container {
        padding: 0 0rem;
    }

    #carousel {
        margin-bottom: 3rem;
    }
}

@include media-breakpoint-down(xs) {
    .opiniones-image img {
        width: 120%;
    }

    h1.title {
        font-size: 25px;
    }

    p.subtitle {
        font-size: 15px;
    }

    .opiniones-image-small {
        display: block;

        img {
            width: 5.7rem;
        }
    }

    .opiniones-title {
        align-items: start;
    }

    .opiniones-image {
        display: none;
    }


}

@include media-breakpoint-between(xs, sm) {
    .title-h1 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    #opiniones {
        .card {
            width: 17rem;
            height: 15rem;
            font-size: 1.8vh;
            font-size: 0.85rem;
        }
    }

    .padding {
        padding: 1.5rem;
    }

    #desktop {
        display: none !important;
    }

    // img {
    //     filter: grayscale(0);
    // }

    #carousel {
        #mobile {
            .card {
                border: none;
                margin-left: 12.5%;
                margin-right: 12.5%;
                height: auto;
                width: 75%;
            }
        }
    }

    .h4-text-verde {
        font-size: 18.5px;
    }

    .h3-text-verde {
        font-size: 50px;
    }

    .h4-text-azul {
        font-size: 18.5px;
    }

    .h3-text-azul {
        font-size: 50px;
    }

    .h4-text-salmon {
        font-size: 18.5px;
    }

    .h3-text-salmon {
        font-size: 50px;
    }
    .banner-container{
        padding-bottom: 1rem !important;
    }

    .opiniones-title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    

}

@include media-breakpoint-between(md, lg) {
    .title-h1 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    #desktop {
        display: none !important;
    }

    .padding {
        padding: 0;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #prensa {
        img {
            filter: grayscale(100);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #socios {
        img {
            filter: grayscale(0);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #carousel {
        margin-bottom: 5rem;

        #mobile {
            .card {
                border: none;
                margin-left: 25%;
                margin-right: 25%;
                height: auto;
                width: 50%;

                img {
                    filter: grayscale(0);
                }
            }
        }
    }
}

@include media-breakpoint-between(xl, xxl) {
    #mobile {
        display: none !important;
    }

    .padding {
        padding: 2.5rem;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #prensa {
        img {
            filter: grayscale(0);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #socios {
        img {
            filter: grayscale(0);
            ;
        }

        img:hover {
            filter: grayscale(0);
        }
    }
}

@import "@/assets/styles/variables.scss";

.btn-mobile {
    height: 60px;
    font-family: 'Montserrat';
    font-weight: 700;
    letter-spacing: 0.03em;
    width: 246px;
}

#container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
        width: calc(100% - 55px);
        position: absolute;
        left: 35px;
        display: inline-block;
        -webkit-appearance: none;
        appearance: none;
        margin: 18px 0;

        &:focus {
            outline: none;
        }

        height: 4px;
        cursor: pointer;
        // background: #ffc864;
        background: $orange-2;
        border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        // background: #ffc864;
        background: $orange-2;
        border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-thumb {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: rgb(255, 200, 100);
        background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        position: sticky;
        margin-top: -18px;
    }

    input[type="range"]::-moz-range-thumb {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        background: rgb(255, 200, 100);
        background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        margin-top: -10px;
        border: transparent;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: $orange-2;
    }
}

.btn_mas,
.btn_menos {
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    background: $orange-2;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 15px;
}

.btn_menos {
    left: 5px;
}

.btn_menos_lg {
    .btn_menos {
        height: 23px;
        width: 23px;
    }

    font-size: x-large;
    position: absolute;
    top: -10px;
    left: 1px;
    color: $blue;
}

.btn_mas_lg {
    .btn_mas {
        height: 23px;
        width: 23px;
        margin-left: 10px;
    }

    font-size: x-large;
    position: absolute;
    top: -10px;
    right: -1px;
    color: $blue;
}

.centrar-form-lg {
    display: flex;
    align-items: center;
}

.consigue {
    font-size: 1rem !important;
    color: $blue;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";



@include media-breakpoint-between(xs, sm) {

    .box-title-h1 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        color: $blue;
    }

    .euros {
        margin-left: -10px;
        font-family: "Montserrat-bold" !important;
        color: $blue !important;
    }

    .box-title-h2 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: bolder;
        font-size: 18px;
        margin-top: 20px;
    }

    .btn {
        font-size: 15px !important;
    }

    .euros {
        font-family: "Montserrat-bold";
        font-size: 14px;
        color: #999999;
        line-height: 32px;
    }

    .minuto {
        font-family: 'Montserrat-Bold';
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        color: #004680;
    }
}

@include media-breakpoint-between(md, lg) {
    .btn {
        width: auto;
        font-family: 'Montserrat-Bold';
    }

    .centrar-form-lg {
        align-items: end;
    }

    #container_input_range {
        position: relative;
        height: 25px;
        margin-top: 0px;

        input[type="range"] {
            width: calc(100% - 70px);

            margin: 14px 0;

            &:focus {
                outline: none;
            }

            height: 2px;
            cursor: pointer;
            // background: #ffc864;
            background: $orange-2;
            border-radius: 20px;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 1px;
        }
    }

    .card-box {
        // width: 90%;
        width: 350px !important;
        height: auto !important;
        padding: 15px !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15) !important;
        border-radius: 40px !important;
        border: 5px solid rgba(255, 200, 100, 0.30) !important;
        background: #FFF !important;
        box-shadow: 2px 11px 5.5px 0px rgba(0, 0, 0, 0.25) !important;
        color: #004680 !important;

    }

    .box-title-h1 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #004680;
    }

    .box-title-h2 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #004680;
    }

    .btn {
        height: auto;
        font-size: 18px !important;
    }

    .euros {
        font-family: "Montserrat-bold";
        font-size: 14px;
        line-height: 32px;
    }

    .md_form_res {
        font-family: "Montserrat-Bold";
        font-size: 3rem;
    }
}

#container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
        width: calc(100% - 70px);
        margin: 16px 0;

        &:focus {
            outline: none;
        }

        height: 1px;
        cursor: pointer;
        background: $orange-2;
        border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        height: 2px;
    }
}

@include media-breakpoint-down(lg) {
    .btn {
        width: auto;
    }

    #container_input_range {
        position: relative;
        height: 25px;
        margin-top: 0px;

        input[type="range"] {
            width: calc(100% - 70px);
            margin: 16px 0;

            &:focus {
                outline: none;
            }

            height: 1px;
            cursor: pointer;
            background: $orange-2;
            border-radius: 20px;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 2px;
        }
    }

    .card-box {
        background-color: $blue !important;
        // width: 90%;
        width: 350px !important;
        height: auto !important;
        padding: 15px !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15) !important;
        border-radius: 40px !important;
        border: 5px solid rgba(255, 200, 100, 0.30) !important;
        background: #FFF !important;
        box-shadow: 2px 11px 5.5px 0px rgba(0, 0, 0, 0.25) !important;
        color: #004680 !important;

    }


    .box-title-h1 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        color: #004680;
    }

    .box-title-h2 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: #004680;
    }

    .euros {
        font-family: "Montserrat-bold";
        font-size: 14px;
        line-height: 32px;
    }
}

@include media-breakpoint-only(xl) {
    .btn {
        font-family: 'Montserrat-Bold';
        width: auto;
    }

    #container_input_range {
        position: relative;
        height: 25px;
        margin-top: 0px;

        input[type="range"] {
            width: calc(100% - 70px);
            margin: 16px 0;

            &:focus {
                outline: none;
            }

            height: 1px;
            cursor: pointer;
            background: $orange-2;
            border-radius: 20px;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 2px;
        }
    }

    .card-box {
        background-color: $blue !important;
        // width: 90%;
        width: 350px !important;
        height: auto !important;
        padding: 15px !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15) !important;
        border-radius: 40px !important;
        border: 5px solid rgba(255, 200, 100, 0.30) !important;
        background: #FFF !important;
        box-shadow: 2px 11px 5.5px 0px rgba(252, 48, 48, 0.25) !important;
        color: #004680 !important;

    }


    .box-title-h1 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        color: #004680;
    }

    .box-title-h2 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: #004680;
    }

    .euros {
        font-family: "Montserrat-bold";
        font-size: 14px;
        line-height: 32px;
    }
}

@include media-breakpoint-down (xs){
    .banner-container {
        margin-top: 5rem !important;
        padding: 0 1rem;
    }

    .card {
        padding: 1rem;
        width: 100%;
        height: 60px;
        font-size: 15px;
    }

    h2.title {
        font-size: 17px !important;
    }
}

.card img {
    width: 140px !important;
    height: 40px !important;
}
</style>